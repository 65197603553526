import { Alert, Button } from "antd";
import { useState } from "react";

const Welcome = () => {
  const [show, setShow] = useState(window.location.hash == "#welcome");

  return (
    <>
      {show && (
        <>
          <Alert
            message={<h2>Welcome!</h2>}
            description={
              <div className="flex gap-12">
                <div className="flex-grow">
                  <p>
                    You account is now <strong>activated</strong> and will be monitored by Cloudwhistle.
                  </p>
                  <p>
                    You can come back here any time to get real-time insights into your cloud cost and
                    re-configure your budget.
                  </p>
                </div>
                <Button
                  type="primary"
                  className="inline-flex items-center"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  Got it
                </Button>
              </div>
            }
            type="info"
            showIcon={false}
            closeIcon={null}
          />
          <div className="mb-4" />
        </>
      )}
    </>
  );
};

export default Welcome;
