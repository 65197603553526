import { signOut } from "firebase/auth";
import { createContainer } from "unstated-next";
import { useAuthState } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../util/firebase";

export const useUser = () => {
  const [user, loading, error] = useAuthState(firebaseAuth);
  const logout = async () => {
    await signOut(firebaseAuth);
    window.location.href = "/signin";
  };

  return {
    user,
    loading,
    error,
    logout,
  };
};

export const User = createContainer(useUser);
