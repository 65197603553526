import { Card, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { asMoney } from "../../util/format";

export interface RowType {
  key: string;
  name: string;
  type: string;
  region: string;
  cost_1h: number;
  cost_24h: number;
}

function renderCost(v: number) {
  return <span className={v === 0 ? "text-neutral-400" : ""}>${asMoney(v)}</span>;
}

const columns: ColumnsType<RowType> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: "25%",
    sorter: (a, b) => a.type.localeCompare(b.type),
    render: (v) => <span className="font-bold">{v}</span>,
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    width: "25%",
    sorter: (a, b) => a.type.localeCompare(b.type),
  },
  {
    title: "Region",
    dataIndex: "region",
    key: "region",
    width: "25%",
    sorter: (a, b) => a.type.localeCompare(b.region),
  },
  {
    title: "Cost (1h)",
    dataIndex: "cost_1h",
    key: "cost_1h",
    width: "12%",
    sorter: (a, b) => a.cost_1h - b.cost_1h,
    render: (v) => renderCost(v),
  },
  {
    title: "Cost (24h)",
    dataIndex: "cost_24h",
    key: "cost_24h",
    width: "12%",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.cost_24h - b.cost_24h,
    render: (v) => renderCost(v),
  },
];

export function List({ rows }: { rows: RowType[] }) {
  return <Table columns={columns} dataSource={rows} className="shadow pb-2" bordered pagination={false} />;
}
