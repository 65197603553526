import InfoBox from "../../shared/infobox";

const BudgetInfo = () => (
  <>
    <InfoBox className="mb-4">
      <>
        <strong>Rate: </strong>
        During the beta, cost is updated once every five minutes.
      </>
    </InfoBox>

    <InfoBox className="mb-4">
      <>
        <strong>Resolve: </strong>
        While a budget alert is triggered, no additional emails will be sent until it has been resolved.
      </>
    </InfoBox>

    <InfoBox className="mb-4">
      <>
        <strong>Limits: </strong>
        You can receive up to 100 email alerts per month per account.
      </>
    </InfoBox>

    <InfoBox>
      <>
        <strong>Support Resources:</strong>
        <ul className="ml-6 list-disc">
          <li>Lambda</li>
          <li>ECS/Fargate</li>
          <li>EC2</li>
        </ul>

        <span className="italic">
          Note that the AWS Free Tier and any AWS Compute Savings Plans are <strong>not </strong> taken into
          account.
        </span>
      </>
    </InfoBox>
  </>
);

export default BudgetInfo;
