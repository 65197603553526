import { ReactNode } from "react";

const InfoBox = ({ children, className }: { children: ReactNode; className?: string }) => (
  <div
    className={`border-l-4 border-gray-400 text-gray-500 bg-gray-50 p-2 text-xs ${className}`}
    role="alert"
  >
    {children}
  </div>
);

export default InfoBox;
