import { Card, Result, Spin } from "antd";
import { ReactElement } from "react";
import { AWSLogo } from "../../shared/logo_aws";
import { AzureLogo } from "../../shared/logo_azure";
import { GCPLogo } from "../../shared/logo_gcp";
import { isEmpty } from "lodash";
import { Case, Switch } from "../../util/switch";

const SelectOption = ({
  logo,
  title,
  disabled,
  onSelect,
  isSelected,
}: {
  logo: ReactElement;
  title: string;
  disabled?: boolean;
  onSelect: () => void;
  isSelected: boolean;
}) => (
  <div
    className={
      "h-20 text-right cursor-pointer hover:bg-white text-gray-800 border-2 py-2 px-6 inline-flex items-center gap-2 hover:grayscale-0 " +
      (isSelected ? "bg-white border-2 border-purple-400" : "bg-gray-50 grayscale") +
      (disabled ? " opacity-50 cursor-not-allowed" : "")
    }
    onClick={() => (disabled ? {} : onSelect())}
  >
    <div className="w-10">{logo}</div>
    <div className="font-semibold text-xs text-center">{title}</div>
  </div>
);

export const SelectVendor = ({
  vendor,
  setVendor,
  accountId,
  error,
}: {
  vendor: string;
  setVendor: (v: string) => void;
  accountId: string;
  error: string;
}) => (
  <Card className="shadow">
    <Switch test={!isEmpty(error) ? "error" : !isEmpty(vendor) && isEmpty(accountId) ? "loading" : ""}>
      <Case value="error">
        <div className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
          <p className="font-bold">A problem occurred</p>
          <p>Please try again by refreshing the page.</p>
          <p>
            If the issue persists, please <a href="mailto:support@cloudwhistle.dev">contact support</a>.
          </p>
        </div>
      </Case>
      <Case value="loading">
        <div className="grid my-12 place-items-center">
          <Spin />
          <br />
          Setting up a new account ...
        </div>
      </Case>
      <Case>
        <>
          <p>Let's get you set up in 3 simple steps.</p>

          <p>First, please select the cloud you'd like to monitor your cost for:</p>
          <div className="mb-6"></div>

          <div className="flex gap-4">
            <div className="flex-1">
              <SelectOption
                logo={<AWSLogo />}
                title="Amazon Web Services"
                isSelected={vendor === "aws"}
                onSelect={() => setVendor("aws")}
              />
            </div>
            <div className="flex-1">
              <SelectOption
                disabled
                logo={<GCPLogo />}
                title="Google Cloud Platform"
                isSelected={vendor === "gcp"}
                onSelect={() => setVendor("gcp")}
              />
            </div>
            <div className="flex-1">
              <SelectOption
                disabled
                logo={<AzureLogo />}
                title="Microsoft Azure"
                isSelected={vendor === "azure"}
                onSelect={() => setVendor("azure")}
              />
            </div>
          </div>
        </>
      </Case>
    </Switch>
  </Card>
);
