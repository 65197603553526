import { Card } from "antd";

const Settings = () => (
  <div className="grid grid-cols-2 gap-8 pb-1">
    <Card title="User Settings" className="shadow"></Card>

    <Card title="Org Settings" className="shadow"></Card>
  </div>
);

export default Settings;
