const Error = () => (
  <div className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
    <p className="font-bold">A problem occurred</p>
    <p>
      Please try again by refreshing the page. <br />
    </p>
    <p>
      If the issue persists, please <a href="mailto:support@cloudwhistle.dev">contact support</a>.
    </p>
  </div>
);

export default Error;
