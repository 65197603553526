import { createContainer } from "unstated-next";
import { firebaseAuth, firestore } from "../util/firebase";
import { collection, orderBy, query, where } from "firebase/firestore";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { head } from "lodash";

export const useAccount = () => {
  const [accounts, loading, error] = useCollectionOnce(
    firebaseAuth.currentUser
      ? query(
          collection(firestore, "accounts"),
          where("ownerId", "==", firebaseAuth.currentUser.uid),
          orderBy("connectedAt", "desc")
        )
      : null
  );
  if (error) {
    console.error(error);
  }
  const account = head(accounts?.docs);

  return {
    account,
    loading,
    error,
  };
};

export const User = createContainer(useAccount);
