import { Spin } from "antd";

const Spinner = () => {
  return (
    <div className="grid my-12 place-items-center">
      <Spin />
    </div>
  );
};

export default Spinner;
