import { Button, InputNumber, Select } from "antd";
import { useState } from "react";

const BudgetForm = ({
  email,
  threshold,
  timeframe,
  updateLabel,
  updating,
  update,
}: {
  email: string;
  threshold: number;
  timeframe: number;
  updateLabel: string;
  updating: boolean;
  update: (threshold: number, timeframe: number) => void;
}) => {
  const [thresholdValue, setThresholdValue] = useState(threshold);
  const [timeframeValue, setTimeframeValue] = useState(timeframe);

  return (
    <div>
      <div className="leading-8">
        <div className="pr-2">When the total cost is above</div>

        <div className="my-2 ml-6">
          <InputNumber
            defaultValue={threshold}
            onChange={(value) => setThresholdValue(value || threshold)}
            min={0.001}
            max={100}
            formatter={(value) => "$" + `${value}`}
          />
        </div>

        <div>over the last</div>

        <div className="my-2 ml-6">
          <InputNumber
            defaultValue={timeframe}
            onChange={(value) => setTimeframeValue(value || threshold)}
            min={1}
            max={24}
          />
          <span className="p-2">hour(s)</span>
        </div>

        <div>
          <div>send an alert to</div>

          <div className="my-2 ml-6">
            <Select
              defaultValue="integration0"
              options={[
                {
                  value: "integration0",
                  label: email,
                },
              ]}
            />
          </div>
        </div>
      </div>

      <div className="mt-6 flex justify-end gap-4">
        <Button
          type="primary"
          htmlType="button"
          loading={updating}
          onClick={() => update(thresholdValue, timeframeValue)}
        >
          {updateLabel}
        </Button>
      </div>
    </div>
  );
};

export default BudgetForm;
