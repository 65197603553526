import { Card, notification, Spin } from "antd";
import { useState } from "react";
import { setBudget } from "../../services/budget";
import { Case, Switch } from "../../util/switch";
import Form from "./form";
import { isEmpty } from "lodash";
import Error from "../../shared/error";
import { firebaseAuth } from "../../util/firebase";
import { ArgsProps } from "antd/lib/notification";
import BudgetInfo from "./info";
import { useAccount } from "../../store/account";
import Spinner from "../../shared/spin";

const Budget = () => {
  const { account, loading, error } = useAccount();

  const accountID = account?.id;
  const rule = account?.data()?.rules?.ruleSets?.default?.rules[0];

  const [api, contextHolder] = notification.useNotification();
  const notificationOpts: ArgsProps = {
    message: "",
    placement: "top",
    className: "mt-12",
  };
  const [updating, setUpdating] = useState(false);
  const update = (threshold: number, timeframe: number) => {
    setUpdating(true);
    setBudget(accountID!, threshold, timeframe)
      .then(() => {
        api.success({
          ...notificationOpts,
          message: "Updated successfully.",
        });
      })
      .catch((err) => {
        api.error({
          ...notificationOpts,
          message: "Failed to update.",
        });
        console.error(err);
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  return (
    <Switch test={!isEmpty(error) ? "error" : loading ? "loading" : ""}>
      <Case value="error">
        <div className="grid my-1 place-items-center">
          <Error />
        </div>
      </Case>
      <Case value="loading">
        <Spinner />
      </Case>
      <Case>
        <div className="my-1">
          <div className="flex justify-center flex-row">
            <div className="basis-80">
              <Card className="shadow">
                {contextHolder}
                <Form
                  threshold={parseFloat(rule?.metadata.threshold) || 1}
                  timeframe={parseInt(rule?.metadata.timeframe) || 1}
                  email={firebaseAuth!.currentUser!.email || ""}
                  updating={updating}
                  update={update}
                  updateLabel="Update"
                />
              </Card>
            </div>

            <div className="w-64 ml-8 mt-1">
              <BudgetInfo />
            </div>
          </div>
        </div>
      </Case>
    </Switch>
  );
};

export default Budget;
