import { Steps } from "antd";
import { useState } from "react";
import { isEmpty } from "lodash";
import { ConnectVendor } from "./connect";
import { SelectVendor } from "./select";
import { createAccount } from "../../services/accounts";
import { Case, Switch } from "../../util/switch";
import { SetBudget } from "./budget";

const Setup = () => {
  const [vendor, setVendor] = useState("");
  const [id, setID] = useState("");
  const [error, setError] = useState("");
  const [connected, setConnected] = useState(false);

  const next = (vendor: string) => {
    setVendor(vendor);
    createAccount(vendor)
      .then((id) => setID(id))
      .catch((err) => {
        setError(err);
        console.error(err);
      });
  };

  const reset = () => {
    setVendor("");
    setID("");
  };

  const connect = () => {
    setConnected(true);
  };

  const current = connected ? 2 : isEmpty(vendor) || isEmpty(id) ? 0 : 1;

  return (
    <div className="font-sans w-full min-h-screen flex flex-col bg-gray-100">
      <div className="grid my-10 place-items-center">
        <div style={{ width: "640px" }}>
          <Steps
            size="small"
            responsive={false}
            progressDot
            current={current}
            items={[
              {
                title: "Select",
              },
              {
                title: "Connect",
              },
              {
                title: "Budget",
              },
            ]}
          />

          <div className="mb-6"></div>

          <Switch test={current.toString()}>
            <Case value={"0"}>
              <SelectVendor vendor={vendor} accountId={id} setVendor={next} error={error} />
            </Case>
            <Case value={"1"}>
              <ConnectVendor vendor={vendor} accountId={id} goBack={reset} didConnect={connect} />
            </Case>
            <Case value={"2"}>
              <SetBudget accountId={id} />
            </Case>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Setup;
