import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

const app = initializeApp({
  apiKey: "AIzaSyDg4I4GTZNKiM_jSlH9DxRU8U4I36n_L0g",
  authDomain: "cloudwhistle-live.firebaseapp.com",
  projectId: "cloudwhistle-live",
  storageBucket: "cloudwhistle-live.appspot.com",
  messagingSenderId: "1050315810383",
  appId: "1:1050315810383:web:a4b0c2b1650b5fb030ac3a",
});

export const firebaseAuth = getAuth(app);
export const firestore = getFirestore(app);
export const functions = getFunctions(app);
