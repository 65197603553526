import { Case, Switch } from "../../util/switch";
import Welcome from "./welcome";
import Error from "../../shared/error";
import { isEmpty } from "lodash";
import { Spin } from "antd";
import { Account } from "database-model";
import { ResourceTable } from "./dashboard";
import { useAccount } from "../../store/account";

const Home = () => {
  const { account, loading, error } = useAccount();

  return (
    <Switch test={!isEmpty(error) ? "error" : loading ? "loading" : ""}>
      <Case value="error">
        <div className="grid my-1 place-items-center">
          <Error />
        </div>
      </Case>
      <Case value="loading">
        <div className="grid my-12 place-items-center">
          <Spin />
        </div>
      </Case>
      <Case>
        <>
          <Welcome />
          <ResourceTable idx={(account?.data() as Account)?.resources} />
        </>
      </Case>
    </Switch>
  );
};

export default Home;
