import { Button, Card, Spin, Modal } from "antd";
import { useState } from "react";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useDocument } from "react-firebase-hooks/firestore";
import { collection, doc } from "firebase/firestore";
import { firestore } from "../../util/firebase";
import { Case, Switch } from "../../util/switch";
import { isEmpty } from "lodash";
import { LockClosedIcon } from "@heroicons/react/24/solid";
import { Account } from "database-model";
import Error from "../../shared/error";

export const ConnectVendor = ({
  vendor,
  accountId,
  goBack,
  didConnect,
}: {
  vendor: string;
  accountId: string;
  goBack: () => void;
  didConnect: () => void;
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const ref = doc(collection(firestore, "accounts"), accountId);
  const [document, loading, error] = useDocument(ref);
  if (error) {
    console.error(error);
  }
  if (document && !isEmpty(document?.data()?.connection)) {
    didConnect();
  }

  const accountData = document?.data() as Account;
  const cliCommand = `aws cloudformation create-stack \\
    --stack-name CloudwhistleBridge \\
    --template-url https://cloudwhistle-bridge.s3.amazonaws.com/template.yaml \\
    --parameters \\
        ParameterKey=CloudwhistleID,ParameterValue=${accountId} \\
        ParameterKey=CloudwhistleSecret,ParameterValue=${accountData?.connectionToken} \\
    --capabilities CAPABILITY_IAM \\
    --region us-east-1`;

  return (
    <Card className="shadow">
      <Switch test={!isEmpty(error) ? "error" : loading ? "loading" : ""}>
        <Case value="error">
          <Error />
        </Case>
        <Case value="loading">
          <div className="grid my-12 place-items-center">
            <Spin />
            <br />
            Loading new account ...
          </div>
        </Case>
        <Case>
          <>
            <Switch test={vendor}>
              <Case value="gcp">
                <></>
              </Case>
              <Case value="azure">
                <></>
              </Case>
              <Case value="aws">
                <>
                  <p>As recommended by AWS, we'll establish a Trust Relationship with your account.</p>

                  <p>
                    We've automated the process for you: all you need to do is install a CloudFormation stack.
                  </p>

                  <div className="grid mt-12 grid-cols-2 divide-x">
                    <div className="grid place-content-center text-center">
                      <Button
                        type="primary"
                        className="shadow"
                        onClick={() => {
                          const url = `https://us-east-1.console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/quickcreate?templateURL=https://cloudwhistle-bridge.s3.amazonaws.com/template.yaml&stackName=CloudwhistleBridge&param_CloudwhistleID=${accountId}&param_CloudwhistleSecret=${accountData?.connectionToken}`;
                          window.open(url, "_blank")!.focus();
                          setWaiting(true);
                        }}
                      >
                        <LockClosedIcon width="16" className="inline mr-1 mb-1" /> Install via AWS Console
                      </Button>

                      <p className="my-3 italic text-gray-500">or</p>

                      <Button
                        className="shadow"
                        onClick={() => {
                          setOpenModal(true);
                          setWaiting(true);
                        }}
                      >
                        Install via AWS CLI
                      </Button>

                      <Modal
                        centered
                        closable
                        open={openModal}
                        onOk={() => setOpenModal(false)}
                        onCancel={() => setOpenModal(false)}
                        width={1000}
                        footer={[
                          <Button
                            onClick={() => {
                              navigator.clipboard.writeText(cliCommand);
                            }}
                          >
                            Copy to Clipboard
                          </Button>,
                        ]}
                      >
                        <blockquote className="font-mono">
                          <textarea
                            readOnly
                            rows={8}
                            className="resize-none w-full whitespace-pre focus:outline-0 focus:outline-offset-0 cursor-default p-4"
                            value={cliCommand}
                          />
                        </blockquote>
                      </Modal>
                    </div>

                    <div className="pl-8 italic text-gray-500">
                      <p>How does this work?</p>
                      <p>
                        The CloudFormation stack will create a cross-account IAM role in us-east-1 that allows
                        us to query your account's resources and their metrics.
                      </p>
                      <p>
                        Of course, feel free to audit the template beforehand. It's been extensively annotated
                        to explain the details.
                      </p>
                    </div>
                  </div>

                  <p className="mt-12">
                    We'll wait here until the installation is complete. It usually only takes a minute or two.
                  </p>
                </>
              </Case>
            </Switch>

            <div className="mt-12 flex justify-between">
              <div>
                <a className="leading-6" onClick={() => goBack()}>
                  <ArrowLeftIcon width="15" className="inline" /> select a different cloud
                </a>
              </div>

              <div
                onClick={() => {
                  if (process.env.NODE_ENV === "development") {
                    didConnect();
                  }
                }}
              >
                {waiting ? <Spin /> : <></>}
              </div>
            </div>
          </>
        </Case>
      </Switch>
    </Card>
  );
};
