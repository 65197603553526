import { ResourceIndex, SpanScope } from "agent-model";
import { forEach } from "lodash";
import Summary from "./summary";
import { List, RowType } from "./list";
import { summary } from "../../util/cost";

export const ResourceTable = ({ idx }: { idx: ResourceIndex }) => {
  let total_1h = 0.0;
  let total_1h_values: number[] = [];
  let total_24h = 0.0;
  let total_24h_values: number[] = [];
  const rows: RowType[] = [];

  const sumById = summary(idx, {
    [5 * 12]: new SpanScope({
      interval: 5,
      limit: 12,
    }),
    [60 * 24]: new SpanScope({
      interval: 60,
      limit: 24,
    }),
  });

  total_1h = sumById.total.scopes[60].total;
  total_1h_values = sumById.total.scopes[60].history.reverse();
  total_24h = sumById.total.scopes[1440].total;
  total_24h_values = sumById.total.scopes[1440].history.reverse();

  forEach(sumById, (sum, id) => {
    if (sum.id) {
      rows.push({
        key: id,
        name: sum.id.name,
        type: `${sum.id.service}/${sum.id.type}`,
        region: sum.id.region,
        cost_1h: sum.scopes[60].total,
        cost_24h: sum.scopes[1440].total,
      });
    }
  });

  return (
    <>
      <Summary
        total_1h={total_1h}
        total_1h_values={total_1h_values.slice(0, -1)}
        total_24h={total_24h}
        total_24h_values={total_24h_values.slice(0, -1)}
      />
      <br />
      <List rows={rows} />
    </>
  );
};
