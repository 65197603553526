import { Outlet } from "react-router-dom";
import { map } from "lodash";
import { Link, useLocation } from "react-router-dom";
import { useUser } from "./store/user";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";

const navbarLinkClasses = "px-3 py-2 text-sm font-medium font-semibold text-gray-800";

const Layout = () => {
  const location = useLocation();
  const { user, logout } = useUser();

  return (
    <div className="font-sans w-full min-h-screen flex flex-col">
      {user ? (
        <nav className="bg-white shadow mb-6">
          <div className="mx-auto">
            <div className="relative flex h-12 items-center">
              <div className="flex flex-1 items-center justify-center">
                <div className="flex space-x-4 ">
                  {map(
                    {
                      Overview: "/",
                      Accounts: "/accounts",
                      Budget: "/budget",
                      // Settings: "/settings",
                    },
                    (route, label) => (
                      <Link
                        to={route}
                        key={route}
                        className={
                          navbarLinkClasses + (location.pathname === route ? " text-purple-600" : "")
                        }
                      >
                        {label}
                      </Link>
                    )
                  )}

                  {/* <a className={navbarLinkClasses + " flex justify-center gap-1"}>
                    Docs
                    <ArrowTopRightOnSquareIcon width={14} />
                  </a> */}

                  <a
                    href="mailto:support@cloudwhistle.dev"
                    className={navbarLinkClasses + " flex justify-center gap-1"}
                  >
                    Support
                    <ArrowTopRightOnSquareIcon width={14} />
                  </a>

                  <a className={navbarLinkClasses} onClick={() => logout()}>
                    Logout
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      ) : (
        <></>
      )}

      <div className="container mx-auto">
        <div className="App mb-6">
          <div className="overflow-x-auto">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
