// @generated by protoc-gen-es v0.1.1 with parameter "target=ts"
// @generated from file action.proto (package cloudwhistle, syntax proto3)
/* eslint-disable */
/* @ts-nocheck */

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage,
} from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message cloudwhistle.ActionTemplate
 */
export class ActionTemplate extends Message<ActionTemplate> {
  /**
   * indicates whether this action is NOT actively triggered
   *
   * @generated from field: bool inactive = 1;
   */
  inactive = false;

  /**
   * @generated from oneof cloudwhistle.ActionTemplate.detail
   */
  detail:
    | {
        /**
         * @generated from field: cloudwhistle.EmailActionTemplate email = 2;
         */
        value: EmailActionTemplate;
        case: "email";
      }
    | {
        /**
         * @generated from field: cloudwhistle.WebhookActionTemplate webhook = 3;
         */
        value: WebhookActionTemplate;
        case: "webhook";
      }
    | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: map<string, string> metadata = 99;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<ActionTemplate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.ActionTemplate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "inactive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "email", kind: "message", T: EmailActionTemplate, oneof: "detail" },
    { no: 3, name: "webhook", kind: "message", T: WebhookActionTemplate, oneof: "detail" },
    {
      no: 99,
      name: "metadata",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "scalar", T: 9 /* ScalarType.STRING */ },
    },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ActionTemplate {
    return new ActionTemplate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ActionTemplate {
    return new ActionTemplate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ActionTemplate {
    return new ActionTemplate().fromJsonString(jsonString, options);
  }

  static equals(
    a: ActionTemplate | PlainMessage<ActionTemplate> | undefined,
    b: ActionTemplate | PlainMessage<ActionTemplate> | undefined
  ): boolean {
    return proto3.util.equals(ActionTemplate, a, b);
  }
}

/**
 * @generated from message cloudwhistle.EmailActionTemplate
 */
export class EmailActionTemplate extends Message<EmailActionTemplate> {
  /**
   * @generated from field: string address = 1;
   */
  address = "";

  /**
   * @generated from field: string subject = 2;
   */
  subject = "";

  /**
   * @generated from field: string body = 3;
   */
  body = "";

  constructor(data?: PartialMessage<EmailActionTemplate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.EmailActionTemplate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "body", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EmailActionTemplate {
    return new EmailActionTemplate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EmailActionTemplate {
    return new EmailActionTemplate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EmailActionTemplate {
    return new EmailActionTemplate().fromJsonString(jsonString, options);
  }

  static equals(
    a: EmailActionTemplate | PlainMessage<EmailActionTemplate> | undefined,
    b: EmailActionTemplate | PlainMessage<EmailActionTemplate> | undefined
  ): boolean {
    return proto3.util.equals(EmailActionTemplate, a, b);
  }
}

/**
 * @generated from message cloudwhistle.WebhookActionTemplate
 */
export class WebhookActionTemplate extends Message<WebhookActionTemplate> {
  /**
   * HTTP method
   *
   * @generated from field: string method = 1;
   */
  method = "";

  /**
   * HTTP URL
   *
   * @generated from field: string url = 2;
   */
  url = "";

  /**
   * HTTP body
   *
   * @generated from field: string body = 3;
   */
  body = "";

  /**
   * HTTP headers
   *
   * @generated from field: map<string, string> headers = 4;
   */
  headers: { [key: string]: string } = {};

  /**
   * HTTP request timeout, in milliseconds
   *
   * @generated from field: uint32 timeout = 5;
   */
  timeout = 0;

  constructor(data?: PartialMessage<WebhookActionTemplate>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.WebhookActionTemplate";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "method", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "body", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    {
      no: 4,
      name: "headers",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "scalar", T: 9 /* ScalarType.STRING */ },
    },
    { no: 5, name: "timeout", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WebhookActionTemplate {
    return new WebhookActionTemplate().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WebhookActionTemplate {
    return new WebhookActionTemplate().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WebhookActionTemplate {
    return new WebhookActionTemplate().fromJsonString(jsonString, options);
  }

  static equals(
    a: WebhookActionTemplate | PlainMessage<WebhookActionTemplate> | undefined,
    b: WebhookActionTemplate | PlainMessage<WebhookActionTemplate> | undefined
  ): boolean {
    return proto3.util.equals(WebhookActionTemplate, a, b);
  }
}

/**
 * @generated from message cloudwhistle.Action
 */
export class Action extends Message<Action> {
  /**
   * @generated from field: cloudwhistle.ActionTemplate config = 1;
   */
  config?: ActionTemplate;

  /**
   * @generated from field: map<string, string> params = 2;
   */
  params: { [key: string]: string } = {};

  constructor(data?: PartialMessage<Action>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime = proto3;
  static readonly typeName = "cloudwhistle.Action";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "config", kind: "message", T: ActionTemplate },
    {
      no: 2,
      name: "params",
      kind: "map",
      K: 9 /* ScalarType.STRING */,
      V: { kind: "scalar", T: 9 /* ScalarType.STRING */ },
    },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Action {
    return new Action().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Action {
    return new Action().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Action {
    return new Action().fromJsonString(jsonString, options);
  }

  static equals(
    a: Action | PlainMessage<Action> | undefined,
    b: Action | PlainMessage<Action> | undefined
  ): boolean {
    return proto3.util.equals(Action, a, b);
  }
}
