import { Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { isEmpty } from "lodash";
import { Case, Switch } from "../../util/switch";
import Error from "../../shared/error";
import InfoBox from "../../shared/infobox";
import { useAccount } from "../../store/account";
import { Account } from "database-model";
import Spinner from "../../shared/spin";

type RowType = {
  vendor: string;
  id: string;
  status: string;
};

const columns: ColumnsType<RowType> = [
  {
    title: "Vendor",
    dataIndex: "vendor",
    width: "25%",
  },
  {
    title: "ID",
    dataIndex: "id",
    width: "25%",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "25%",
  },
  // {
  //   title: "Actions",
  //   key: "action",
  //   width: "15%",
  //   render: () => (
  //     <div className="text-center">
  //       <a href="#">Remove</a>
  //     </div>
  //   ),
  // },
];

const Accounts = () => {
  const { account, loading, error } = useAccount();

  const rows = !account
    ? []
    : [account.data() as Account].map((d) => ({
        key: d.vendorAccountId,
        vendor: d.vendor.toUpperCase(),
        id: d.vendorAccountId,
        status: "Active",
      }));

  return (
    <Switch test={!isEmpty(error) ? "error" : loading ? "loading" : ""}>
      <Case value="error">
        <div className="grid my-1 place-items-center">
          <Error />
        </div>
      </Case>
      <Case value="loading">
        <Spinner />
      </Case>
      <Case>
        <div className="flex justify-center gap-6">
          {/* <div className="flex justify-end mb-6">
            <Tooltip title={"We'll add the ability to have multiple accounts soon!"}>
              <Button type="primary" className="shadow" disabled>
                Add Account
              </Button>
            </Tooltip>
          </div> */}
          <div className="basis-96">
            <Table columns={columns} dataSource={rows} bordered pagination={false} />
          </div>
          <div className="basis-60">
            <InfoBox>
              <p>Here you can see the cloud accounts you have activated successfully.</p>

              <p className="italic">Support for multiple accounts is on the roadmap.</p>
            </InfoBox>
          </div>
        </div>
      </Case>
    </Switch>
  );
};

export default Accounts;
