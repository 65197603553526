import React, { ReactElement } from "react";
import ReactDOM from "react-dom/client";
import { Navigate, createBrowserRouter, RouterProvider, redirect } from "react-router-dom";

import "./index.css";
import "antd/dist/antd.less";

import { User, useUser } from "./store/user";

import Layout from "./layout";
import Home from "./pages/live";
import Budget from "./pages/budget";
import NotFound from "./pages/notfound";
import Setup from "./pages/setup";
import Settings from "./pages/settings";
import Accounts from "./pages/accounts";
import Signin from "./pages/signin";
import Error from "./shared/error";
import { useAccount } from "./store/account";
import { signOut } from "firebase/auth";
import { firebaseAuth } from "./util/firebase";
import Spinner from "./shared/spin";

// if (process.env.NODE_ENV === "development") {
//   const { worker } = await import("./store/msw");
//   worker.start();
// }

const ProtectedRoute = ({ children }: { children: ReactElement }) => {
  const { loading, error, user } = useUser();
  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <Error />;
  }
  if (!user) {
    console.log("user is not logged-in; redirecting");
    return <Navigate to="/signin" replace />;
  }
  return children;
};

const RequireSetupRoute = ({ children }: { children: ReactElement }) => {
  const { loading, error, account } = useAccount();
  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <Error />;
  }
  if (!account) {
    console.log("account is not set up; redirecting");
    return <Navigate to="/setup" replace />;
  }
  return children;
};

const router = createBrowserRouter([
  {
    path: "/signin",
    element: <Signin />,
  },
  {
    path: "/logout",
    loader: async () => {
      await signOut(firebaseAuth);
      throw redirect("/signin");
    },
    element: <></>,
  },
  {
    path: "/setup",
    element: (
      <ProtectedRoute>
        <Setup />
      </ProtectedRoute>
    ),
  },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <RequireSetupRoute>
          <Layout />
        </RequireSetupRoute>
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/budget",
        element: <Budget />,
      },
      {
        path: "/accounts",
        element: <Accounts />,
      },
      {
        path: "/accounts",
        element: <Settings />,
      },
      // {
      //   path: "/integrations",
      //   element: (
      //     <RequireSetupRoute>
      //       <Integrations />
      //     </RequireSetupRoute>
      //   ),
      // },
      // {
      //   path: "/settings",
      //   element: (
      //     <RequireSetupRoute>
      //       <Settings />
      //     </RequireSetupRoute>
      //   ),
      // },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <User.Provider>
      <RouterProvider router={router} />
    </User.Provider>
  </React.StrictMode>
);
