const NotFound = () => (
  <section className="flex items-center h-full p-16 dark:bg-gray-900 dark:text-gray-100">
    <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
      <div className="max-w-md text-center">
        <p className="text-2xl font-semibold md:text-3xl">Sorry, we couldn't find this page.</p>
        <a
          rel="noopener noreferrer"
          href="/"
          className="px-8 py-3 font-semibold rounded dark:bg-violet-400 dark:text-gray-900"
        >
          Back to start page
        </a>
      </div>
    </div>
  </section>
);

export default NotFound;
