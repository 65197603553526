import { Alert, Card, notification } from "antd";
import { useState } from "react";
import { setBudget } from "../../services/budget";
import { firebaseAuth } from "../../util/firebase";
import Form from "../budget/form";
import BudgetInfo from "../budget/info";

export const SetBudget = ({ accountId }: { accountId: string }) => {
  const [updating, setUpdating] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const update = (threshold: number, timeframe: number) => {
    setUpdating(true);
    setBudget(accountId, threshold, timeframe)
      .then(() => {
        window.location.href = "/#welcome";
      })
      .catch((err) => {
        api.error({
          placement: "top",
          className: "mt-12",
          message: "An error occurred.",
        });
        console.error(err);
      })
      .finally(() => {
        setUpdating(false);
      });
  };

  return (
    <div className="grid my-1 place-items-center">
      <Alert
        description={
          "As the last step, let's set up a budget so we can notify you. You can adjust this ay any time."
        }
      />
      <br />

      <div className="flex">
        <div className="flex-grow">
          <Card className="flex-grow shadow">
            {contextHolder}

            <Form
              email={firebaseAuth!.currentUser!.email || ""}
              threshold={1}
              timeframe={1}
              updating={updating}
              update={update}
              updateLabel="Confirm"
            />
          </Card>
        </div>

        <div className="basis-60 ml-8 mt-1">
          <BudgetInfo />
        </div>
      </div>
    </div>
  );
};
